let { hostname} = window.location;
let AppInfo = {};
let INFO = {
    imgage:'https://cdn.bzxd.tv/mydemo/demoimg1.png',
    imgage_1:'https://cdn.bzxd.tv/mydemo/demoimg1-1.png',
    icp:'蜀ICP备2021003210号-4',
    appdown:'https://cdn.ryruyue.com/app/ruyue_official.apk',
    appname:'不止心动（成都）网络科技有限公司',
    address:''
    
}
console.log(hostname,hostname.match('bzxd.top'));
if(hostname.match("ryruyue")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg1.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg1-1.png'
    INFO.appdown='https://cdn.ryruyue.com/app/ruyue_official.apk'
  }
  if(hostname.match("mxmixin")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg2.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg2-1.png'
    INFO.appdown='https://cdn.bzxd.tv/mx/mixin_official.apk'
    INFO.icp='蜀ICP备2021003210号'
  }
  // mymeiyue
  if(hostname.match("mymeiyue")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg3.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg3-1.png'
    INFO.icp='蜀ICP备2021003210号-7'
    INFO.appdown='https://cdn.bzxd.tv/my/meiyue_official.apk'
  }
  //mixinmixin
  if(hostname.match("mixinmixin")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg4.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg4-1.png'
    INFO.icp='蜀ICP备2021003210号-5'
    INFO.appdown='https://cdn.mixinmixin.com/app/mixin2_official.apk'
  }
  //mmmoyan
  if(hostname.match("mmmoyan")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg5.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg5-1.png'
    INFO.icp='蜀ICP备2021003210号-6'
    INFO.appdown='https://cdn.mmmoyan.com/moyan/moyan_official.apk'

  }
  if(hostname.match('bzxd.top')){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg6.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg6-1.png'
    INFO.icp='蜀ICP备2021003210号-12'
    INFO.appdown='https://cdn.ryruyue.com/distinguished/jiaren_official.apk'
  }
  //yqyunqing.cn
  if(hostname.match("yqyunqing")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg7.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg7-1.png'
    INFO.icp='蜀ICP备2021003210号-22'
    INFO.appdown='https://cdn.yqyunqing.cn/yunqing/yunqing_official.apk'

  }
  //fhfenghua.cn
  if(hostname.match("fhfenghua")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg8.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg8-1.png'
    INFO.icp='蜀ICP备2021003210号-21'
    INFO.appdown='https://cdn.fhfenghua.cn/fh/fenghua_official.apk'

  }
  //qyqiaoyuan.cn
  if(hostname.match("qyqiaoyuan")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg9.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg9-1.png'
    INFO.icp='蜀ICP备2021003210号-15'
    INFO.appdown='https://cdn.qyqiaoyuan.cn/app/qy/qiaoyuan_official.apk'
  }
  //haohehaohe.cn
  if(hostname.match("haohehaohe")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg10.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg10-1.png'
    INFO.icp='蜀ICP备2021003210号-20'
    INFO.appdown='https://cdn.haohehaohe.cn/haohe/haohe_official.apk'

  }
  //cdchengdui.cn
  if(hostname.match("cdchengdui")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg11.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg11-1.png'
    INFO.icp='蜀ICP备2021003210号-19'
    INFO.appdown='https://cdn.cdchengdui.cn/cd/chengdui_official.apk'
  }
  // jiubanapp
  if(hostname.match("jiubanapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg12.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg12-1.png'
    INFO.icp='琼ICP备2024019521号-1'
    INFO.appname='海南豪旺网络科技有限公司'
    INFO.appdown='https://cdn.jiubanapp.com/app/jiuban/jiuban_official.apk'

    // 海南豪旺网络科技有限公司
  }

//huanyuanapp
  if(hostname.match("huanyuanapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg13.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg13-1.png'
    INFO.icp='琼ICP备2024018839号-2'
    INFO.appname='海南满乐游网络科技有限公司'
    INFO.appdown='https://cdn.huanyuanapp.cn/hyapp/huanyuan_official.apk'
  }

//youyuanonline.cn
if(hostname.match("youyuanonline")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg14.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg14-1.png'
  INFO.icp='琼ICP备2024018839号-1'
  INFO.appname='海南满乐游网络科技有限公司'

}
// hnzhengqiang.cn
if(hostname.match("hnzhengqiang.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg15.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg15-1.png'
  INFO.icp='琼ICP备2024016412号-1'
  INFO.appname='海南正强网络科技有限公司'
}
if(hostname.match("hehuanapp.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg16.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg16-1.png'
  INFO.icp='琼ICP备2024016412号-3'
  INFO.appname='海南正强网络科技有限公司'
  
}
if(hostname.match("huanyueapp.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg17.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg17-1.png'
  INFO.icp='琼ICP备2024016412号-2'
  INFO.appname='海南正强网络科技有限公司'
}
if(hostname.match("ruyuanapp.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg18.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg18-1.png'
  INFO.icp='琼ICP备17005324号-16'
  INFO.appname='海南尼豆网络科技有限公司'
  INFO.appdown='https://cdn.ruyuanapp.cn/ruyuan/ruyuan_official.apk'
}
if(hostname.match("jiuyuanapp.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg19.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg19-1.png'
  INFO.icp='琼ICP备17005324号-17'
  INFO.appname='海南尼豆网络科技有限公司'
  INFO.appdown='https://cdn.jiubanapp.com/app/jiuban/jiuban_official.apk'
}
if(hostname.match("qtqingtou.cn")){
  INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg20.png'
  INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg20-1.png'
  INFO.icp='琼ICP备2024019521号-6'
  INFO.appname='海南豪旺网络科技有限公司'
  INFO.appdown='https://cdn.jiubanapp.com/app/jiuban/jiuban_official.apk'
}

  //cschengshuang.cn
  if(hostname.match("cschengshuang")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg21.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg21-1.png'
    INFO.icp='蜀ICP备2021003210号-26'
    INFO.appdown='https://cdn.cschengshuang.cn/cs/chengshuang_official.apk'
  }

    //qingxinliaoqx.cn
  if(hostname.match("qingxinliaoqx")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg22.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg22-1.png'
    INFO.icp='琼ICP备2024019521号-5'
    INFO.appdown='https://cdn.qingxinliaoqx.cn/qxl/qingxinliao_official.apk'
    INFO.appname='海南豪旺网络科技有限公司'

  }

      //xinxinliao.cn
      if(hostname.match("xinxinliao")){
        INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg23.png'
        INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg23-1.png'
        INFO.icp='琼ICP备2024019521号-7'
        INFO.appdown=''
        INFO.appname='海南豪旺网络科技有限公司'

      }
       //changyuanapp.cn
  if(hostname.match("changyuanapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg24.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg24-1.png'
    INFO.icp='沪ICP备2023021267号-10'
    INFO.appdown=''
    INFO.appname='上海璟栝网络科技有限公司'

  }
  //qingqianapp.cn
  if(hostname.match("qingqianapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg25.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg25-1.png'
    INFO.icp='沪ICP备2023021267号-9'
    INFO.appdown=''
    INFO.appname='上海璟栝网络科技有限公司'

  }
  //ylyulian.cn
  if(hostname.match("ylyulian")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg30.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg30-1.png'
    INFO.icp='沪ICP备2023021267号-11'
    INFO.appdown=''
    INFO.appname='上海璟栝网络科技有限公司'

  }
  //yuanquapp.cn
  if(hostname.match("yuanquapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg27.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg27-1.png'
    INFO.icp='沪ICP备2023021267号-7'
    INFO.appdown='yuanquapp.cn'
    INFO.appname='上海璟栝网络科技有限公司'

  }
  //dayliao.cn
  if(hostname.match("dayliao")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg28.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg28-1.png'
    INFO.icp='琼ICP备2024019521号-3'
    INFO.appdown='https://cdn.dayliao.cn/tiantianliao/tiantianliao_official.apk'
    INFO.appname='海南豪旺网络科技有限公司'

  }
  //joyliao.cn
  if(hostname.match("joyliao")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg29.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg29-1.png'
    INFO.icp='琼ICP备2024019521号-4'
    INFO.appdown='https://cdn.joyliao.cn/app/liaohuan/liaohuan_official.apk'
    INFO.appname='海南豪旺网络科技有限公司'

  }
  //yuyuanyy.cn
  if(hostname.match("yuyuanyy")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg26.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg26-1.png'
    INFO.icp='沪ICP备2023021267号-8'
    INFO.appdown=''
    INFO.appname='上海璟栝网络科技有限公司'
  }

  //yiqiwanban.cn 
  if(hostname.match("yiqiwanban")){
    // INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg31.png'
    // INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg31-1.png'
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg32_2.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-1.png'
    INFO.imgage_2 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-2.png'
    INFO.icp='沪ICP备2024074023号-3'
    INFO.appdown=''
    INFO.appname='上海启豆网络科技有限公司'
  }
  
  //wanmeiwanban.cn 
  if(hostname=="wanmeiwanban.cn"){
    // INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg32_2.png'
    // INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-1.png'
    // INFO.imgage_2 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-2.png'
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/wmimg.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/wmimg_1.png'
    INFO.imgage_2 = 'https://cdn.bzxd.tv/mydemo/wmimg_2.png'
    INFO.icp='沪ICP备2024074023号-2'
    INFO.appdown=''
    INFO.appname='上海启豆网络科技有限公司'
  }

  //wanmeidazi.com
  if(hostname.match("wanmeidazi.com")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/wmimg.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/wmimg_1.png'
    INFO.imgage_2 = 'https://cdn.bzxd.tv/mydemo/wmimg_2.png'
    INFO.icp='沪ICP备2024074023号-6'
    INFO.appdown=''
    INFO.appname='上海启豆网络科技有限公司'
  }

  //home.wanmeiwanban.cn
  if(hostname.match("home.wanmeiwanban.cn")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg32_2.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-1.png'
    INFO.imgage_2 = 'https://cdn.bzxd.tv/mydemo/demoimg32_2-2.png'
    INFO.icp='琼ICP备2024074023号-2'
    INFO.appdown=''
    INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }

  //langmanmanwuapp.cn
  if(hostname.match("langmanmanwuapp")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg33.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg33-1.png'
    INFO.icp='琼ICP备17005324号-24'
    INFO.appdown=''
    // INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }

  //yuanfensupeiwu.cn
  if(hostname.match("yuanfensupeiwu")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg34.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg34-1.png'
    INFO.icp='琼ICP备17005324号-21'
    INFO.appdown=''
    // INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }

  //suixinyuyou.cn
  if(hostname.match("suixinyuyou")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg35.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg35-1.png'
    INFO.icp='琼ICP备17005324号-20'
    INFO.appdown=''
    // INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }

  //xinyumiban.cn
  if(hostname.match("xinyumiban")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg36.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg36-1.png'
    INFO.icp='琼ICP备17005324号-22'
    INFO.appdown=''
    // INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }

  //yuanfenriji.cn
  if(hostname.match("yuanfenriji")){
    INFO.imgage = 'https://cdn.bzxd.tv/mydemo/demoimg37.png'
    INFO.imgage_1 = 'https://cdn.bzxd.tv/mydemo/demoimg37-1.png'
    INFO.icp='琼ICP备17005324号-23'
    INFO.appdown=''
    // INFO.address='公司地址：海南省澄迈县老城高新技术产业示范区海南生态软件园A17幢一层3001'
    INFO.appname='海南尼豆网络科技有限公司'
  }
  
  

  AppInfo = { ...INFO }; 

export { AppInfo };